import * as React from 'react'
import Col from 'react-bootstrap/Col'
import {
  GatsbyImage, getImage, StaticImage, getSrcSet,
} from 'gatsby-plugin-image'
import Card from 'react-bootstrap/Card'

const ArticleBlock = ({ block }) => {
  const year = block.article.date.substring(0, 4)
  const month = block.article.date.substring(4, 6)
  const day = block.article.date.substring(6, 8)

  const articleDate = new Date(year, month - 1, day).toLocaleString('en-us', { month: 'long', day: 'numeric', year: 'numeric' })

  return (
    <Col md="3" className="d-flex flex-grow-1 news-col">
      <a href={block.article.url} target="_blank" rel="noopner noreferrer" className="card text-dark text-start news-card">
        <div
          className="article-img d-flex align-items-center justify-content-center"
          style={{
            backgroundImage:
            `linear-gradient(180deg, rgba(0,51,51,0.3) 0%, rgba(0,51,51,0.4) 100%), url(${block.article.bg_img.publicUrl})`,
          }}
        >
          <GatsbyImage
            image={getImage(block.article.logo)}
            alt={block.article.alt || 'image'}
            className="article-logo"
          />
        </div>
        <Card.Body>
          <Card.Text className="text-muted mb-12">
            {articleDate}
          </Card.Text>
          <Card.Title className="mb-0">
            {block.title}
          </Card.Title>
        </Card.Body>
      </a>
    </Col>
  )
}

export default ArticleBlock
