import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLevelDownAlt } from '@fortawesome/pro-solid-svg-icons'

import Obfuscate from 'react-obfuscate'
import ArticleBlock from '../components/sections/parts/article-block'

import MainCTA from '../components/sections/main-cta'
import Seo from '../components/seo'
import Layout from '../components/layout'

const NewsPage = () => {
  const articles = useStaticQuery(graphql`
  query {
    articles: allWpArticle(
      filter: {news: {backgroundImage: {guid: {ne: null}}}}
      sort: {fields: news___publicationDate, order: DESC}
    ) {
      edges {
        node {
          title
          article: news {
            date: publicationDate
            url: link
            logo {
              gatsbyImage(formats: PNG, layout: FULL_WIDTH, width: 300)
            }
            bg_img: backgroundImage {
              publicUrl
            }
          }
        }
      }
    }
    releases: allWpArticle(
      filter: {news: {backgroundImage: {guid: {eq: null}}}}
      sort: {fields: pressRelease___publicationDate, order:DESC}
    ) {
      edges {
        node {
          title
          article: pressRelease {
          date: publicationDate
            url:link
          }
        }
      }
    }
  }
`)

  const articleBlocks = articles.articles.edges.map((node, index) => <ArticleBlock key={`article-${index}`} block={node.node} />)

  const prBlocks = articles.releases.edges.map((node, index) => {
    const year = node.node.article.date.substring(0, 4)
    const month = node.node.article.date.substring(4, 6)
    const day = node.node.article.date.substring(6, 8)

    const articleDate = new Date(year, month - 1, day).toLocaleString('en-us', { month: 'long', day: 'numeric', year: 'numeric' })

    return (
      <li className="list-group-item d-flex flex-column" key={`release-${index}`}>
        <date className="mb-14">{articleDate}</date>
        <a href={node.node.article.url} target="_blank" rel="noopner noreferrer" className="lead">
          {node.node.title}
        </a>
      </li>
    )
  })

  return (
    <Layout>
      <Seo title="News" />
      <header className="text-center py-3 py-lg-4 py-xl-5 layered-content">
        <Container>
          <h1 className="mb-1 mb-md-2 font-swish">Meati™ in the news</h1>
          <p className="lead mb-md-2">
            <Obfuscate
              email="press@meati.com"
              obfuscateChildren={false}
              linkText="Press inquiries"
              headers={{
                subject: 'Press inquiry from Meati.com',
              }}
              aria-label="Press inquiries"
            >
              <FontAwesomeIcon className="me-12" icon={faEnvelope} />
              Press inquiries
            </Obfuscate>
            <a href="#pressReleases" className="ms-2">
              <FontAwesomeIcon className="me-12" icon={faLevelDownAlt} />
              Press releases
            </a>
          </p>
        </Container>
        <section id="newsArticles">
          <Container fluid="lg">
            <Row className="g-1 g-lg-2 pt-1 pt-md-2">
              { articleBlocks }
            </Row>
          </Container>
        </section>
        <section id="pressReleases" className="text-start pt-3 pt-md-5">
          <Container>
            <h3>Press Releases</h3>
            <ul className="list-group list-group-flush">
              { prBlocks }
            </ul>
          </Container>
        </section>
      </header>
      <MainCTA />
    </Layout>
  )
}

export default NewsPage
